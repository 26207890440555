<template id="confirm-dialog" style="display: none;">
    <div>
        <el-dialog
            center
            :title="title"
            :visible.sync="visible"
            :show="show"
            :width="dialogWidth"
            custom-class="confirm-dialog"
            :show-close="isShowClose"
            :close-on-click-modal="false"
            @close="$emit('update:show', false)"
        >
            <slot name="content"></slot>
            <div slot="footer" v-if="isShow" class="dialog-footer" >
                <el-button size="small" @click="visible = false" v-if="hasCancelBtn">取消</el-button>
                <el-button size="small" type="primary" @click="confirm" style="margin-left: 16px;">{{confirmText}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
  name: 'confirm-dialog',
  props:{
        //   标题
        title: {
            type:String,
            default: '提示'
        },
        //   隐藏/显示
        show: {
            type: Boolean,
            default: true
        },
        //   弹窗宽度
        dialogWidth: {
            type: String,
            default: '365px'
        },
        //   是否显示底部
        isShow: {
            type: Boolean,
            default: true
        },
        //   是否又取消按钮
        hasCancelBtn: {
            type: Boolean,
            default: true
        },
        // 确认按钮文本
        confirmText: {
            type: String,
            default: '确定'
        },
    //   是否显示header关闭按钮
        isShowClose: {
        type: Boolean,
        default: true
        }

  },
  data() {
    return {
        visible: this.show,
    };
  },
  computed:{
  },
  watch:{
    "show":{
        handler(val){
            this.visible = this.show
        },
        deep: true
    }
  },
  methods: {
    //   点击确认按钮
    confirm () {
        this.$emit('handle-confirm')
    }
  },
};
</script>

<style scoped>

</style>
