import { render, staticRenderFns } from "./SearchCom.vue?vue&type=template&id=4016250a&scoped=true"
import script from "./SearchCom.vue?vue&type=script&lang=js"
export * from "./SearchCom.vue?vue&type=script&lang=js"
import style0 from "./SearchCom.vue?vue&type=style&index=0&id=4016250a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4016250a",
  null
  
)

export default component.exports