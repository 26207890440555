<template>
  <div>
    <section class="top_search">
      <!-- logo -->
      <div class="row_logo">
        <img src="~/assets/img/welfareMall/logo.png" alt="logo" />
        <div class="logo_text">
          <p>员工N选1</p>
          <p>企业集采、员工自领</p>
        </div>
      </div>
      <!-- 搜索框 -->
      <div :class="$route.name =='WelfareHome'?'row_search':'row_search page_search'" v-show="isHide">
        <el-input placeholder="请输入商品或礼包名称" v-model="searchValue" class="input-with-select input_box" autocomplete="off"></el-input>
        <el-button class="serch_btn" @click="searchBtn" slot="append" type="primary">搜索</el-button>
      </div>      
      <!-- 购物车 -->
      <div @click="goCart" class="my_cart_box" v-show="isHide">
        <el-badge :value="cartNum" :max="99" class="item">
          <img src="~/assets/img/welfareMall/gwc_icon.png" />
        </el-badge>
        <p class="myCartText">我的购物车</p>
      </div> 
      <!-- 公司信息 -->
      <div v-if="companyBoxShow && $route.name !='WelfareHome'" class="row_company">
        <div @mouseenter="mouseenterEvent(companyInfo.auditStatus)" @mouseleave="mouseleaveEvent(companyInfo.auditStatus)" class="img_box">
          <img :src="companyInfo.userAvatarUrl" referrerpolicy="no-referrer"/>
          <span v-if="isAuth" :class="[companyInfo.auditStatus == '2'?'yesAuth':companyInfo.auditStatus == '1'?'':'noAuth', 'Auth']">{{companyInfo.auditStatus == '2'?'已认证':companyInfo.auditStatus == '1'?'认证中' : '未认证'}}</span>
          <span v-else @click="goAuthBtn(companyInfo.userID)" class="goAuth">去认证</span>
        </div>
        <div style="display: flex;align-items:center;">
          <span class="companyName">{{companyInfo.companyName?companyInfo.companyName : companyInfo.shortCompanyName}}</span>
          <i class="change_icon" v-if="userData.companyList.length > 1" @click="switch_iconBtn"></i>
        </div>
      </div>
    </section>   
      
    <!-- 切换company弹窗 -->
    <confirm-dialog
      :show.sync="switchCompanyDialog.show"
      :title="switchCompanyDialog.title"
      :dialog-width="switchCompanyDialog.width"
      :is-show="switchCompanyDialog.isShow"
      >
      <span slot="content">
        <ul class="company_box">
          <li @click="switchCompanyBtn(item)" v-for="item in userData.companyList" :key="item.userID">{{item.companyName?item.companyName: 'null'}}</li>
        </ul>
      </span>
    </confirm-dialog>

    <!-- 去认证弹窗 -->
    <confirm-dialog 
      class="auth_dialog"
      :show.sync="authDialog.show"
      :title="authDialog.title"
      :dialog-width="authDialog.width"
      :is-show="authDialog.isShow"
      >
      <span slot="content">
        <div class="attention"> 
          <div class="msg">
            <p>尊敬的用户：</p>
            <div class="text_left">
              <p class="mb25">您好！目前壹人事的合作模式为在线一站式操作，需要在
                <span class="text_color_blue">官网注册-进行企业认证-线上签订协议。</span>
              </p>
              <div class="title_box">
                <p class="text_color_blue_blod">认证过程：</p>
                <!-- <div class="img"></div> -->
                <img class="img" src="~assets/img/welfareMall/auth_bg.png" alt="">
                  <div class="img_text_box">
                    <div>管理员认证</div>
                    <div>填写企业信息</div>
                    <div>等待信息审核</div>
                    <div>核对打款信息</div>
                    <div>完成企业认证</div>
                  </div>
              </div>
              <div class="title_box mb25">
                  <span class="text_color_blue_blod">认证说明 </span> <span class="text_color_blue">（两种认证方式）：</span>
                  <p>1、法人身份证认证（法人提供身份证认证）</p>
                  <p>2、管理员身份认证（需要管理员人脸识别，其中人脸识别为即时性操作，系统并不保留，只作为本次认证自主有效性证明，另外还需要下载“企业认证申请表”，管理员签字并盖公章，才证明其有效）</p>
              </div>
              <div class="title_box mb25">
                <p>
                  <span class="text_color_blue_blod">核对打款信息：</span>法大大给企业打款，贵司财务确认到账，流程无需企业付款。
                </p>
                <p>企业认证是我司委托给法大大进行审核备案，<span class="text_color_black_blod">法大大是具有电子认证审核资格</span> ，是依法通过国务院信息产业主管部门的审查备案，获取了电子认证许可证书，该认证仅为在壹人事平台上操作有效。</p>
              </div>
              <div class="title_box mb25">
                <p>注：根据中国人民共和国电子签名法第四章第三十二条规定：伪造、冒用、盗用他人的电子签名，构成犯罪的，依法追究刑事责任；给他人造成损失的，依法承担民事责任。<span class="text_color_black_blod"> 签订协议需要通过认证手机号码验证才能生效</span>，请妥善保管，非本人操作不要提供验证码，若需变更认证手机号码，需要重新认证。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog_footer_box">
          <el-button plain class="cancleBtn" @click="cancleAuthBtn" size="small">暂不认证</el-button>
          <el-button type="primary" class="confirmBtn" @click="confirmAuthBtn" size="small">进行认证</el-button>
        </div>
      </span>
    </confirm-dialog>
  </div>
</template>

<script>
import $ from 'jquery';
import {mapState, mapMutations } from 'vuex';
import ConfirmDialog from '../publicCom/ConfirmDialog.vue';
import {getReqUserInfo,getReqGoAuth} from '@/network/welfareMall_api';
export default {
  components: { ConfirmDialog },
  name: 'SearchCom',
  props: {
      searchValStr: {
          type: String,
          default: ''
      },
      isHide: {
        type: Boolean,
        default: true
      }
      
  },
  data() {
    return {
      companyBoxShow:false,
      userData:{},
      searchValue: '',
      // switch 切换公司t
      switchCompanyDialog: {
        title: '请选择您要登录的公司',
        show: false,
        width: '360px',
        isShow: false,
      },
      // 法大大认证弹窗
      authDialog:{
        title: '法大大认证',
        show: false,
        width: '800px',
        isShow: false
      },
      cartCount: 0,//购物车数量
      isAuth: true,
      userID: '',//认证所需userID
      jumpLoginLink:this.preUrlOld,
    };
  },
  watch:{
    "searchValStr": {
        handler: function (val) {
          if(val){
              this.searchValue = val;
          }
        }
    },
  },
  computed:{
    // 映射state
    ...mapState(['useInfo', 'companyInfo', 'cartNum']),
  },
  created() {
    if(this.searchValStr){
      this.searchValue = this.searchValStr;
    }
    this.loginUser();
  },
  methods: {
    loginUser(){
       setTimeout(() => {
        // console.log(this.useInfo,'loginUser')
        if(this.useInfo){
          this.userData= this.useInfo;
          this.chooseCompany();
        }
      }, 1000);
    },
    chooseCompany(){
      if(this.userData.userType == 'P'){
        if(this.userData.companyList.length==0){
          // this.$store.commit('openAddCompany');
        }else if(this.userData.companyList.length > 1 && this.$store.state.chooseCompanyNum){ 
          //第一次进入且企业数大于1时 必须要选择企业
          this.switchCompanyDialog.show = true;
          $('.el-dialog__headerbtn:eq(0)').css('display','none')
          this.$store.commit('updataNum',0);
        }else if(this.userData.companyList.length == 1 && !this.userData.userInfo ){
          this.switchCompanyBtn(this.userData.companyList[0]);
        }else{
          console.log(this.useInfo)
          console.log(this.userData)
        }
      }else if(this.userData.userType == 'C'){
        this.companyBoxShow = true;
      }else{
        return
      }
    },
    // 映射mutations 方法
    ...mapMutations(['saveCompanyInfo']),
    // 取消认证
    cancleAuthBtn () {
      this.authDialog.show = false;
    },
    // 弹窗 点击认证
    async confirmAuthBtn () {
      var _this = this;
      var res = await getReqGoAuth(_this.userID);
      if(res.isSuccess){
        var token = `Bearer ${res.data.token}`
        $.ajax({
            type: "get",
            url: `${_this.preUrlApi}/Contract/CompanyVerify`,
            contentType: "application/json",
            dataType: "json",
            beforeSend: function (request){
                request.setRequestHeader("Authorization", token)
            },
            success: function (res2) {
                if(res2.isSuccess){
                    var a = document.createElement('a');
                    a.href = res2.data;
                    a.style.display = 'none';
                    a.target = '_blank';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }else{
                    _this.$message.error(res2.msg);
                }
                _this.authDialog.show = false;
            }
        });
      }else{
        _this.$message.error(res.msg);
      }
      
    },
    // 点击去认证
    goAuthBtn (userID) {
      // this.userID = userID;
      // this.authDialog.show = true;
      this.goSimpleAuthPage(userID, 1);

    },
    // 鼠标移入company img
    mouseenterEvent (e){
      if(e == '0' || e == '3'){
        this.isAuth = false;
      }
    },
    //鼠标离开
    mouseleaveEvent(e){
      if(e == '0' || e == '3'){
        this.isAuth = true;
      }
    },
    // 点击前往购物车
    goCart () {
      document.cookie = "vueRouter="+ '/welfareShoppingCart';
      this.$router.push('/welfareShoppingCart');
    },
    // 点击图标切换
    switch_iconBtn () {
      this.switchCompanyDialog.show = true;
    },
    // 切换公司重新登录
    async reactLogin (userId){
      var _this = this;
      $.ajax({
        url: `${this.preUrlOld}/Account/OfficeLoginByToken?token=` + this.GetTokenFromCookie('pcToken') + '&userId=' + userId,
        type: 'get',
        timeout: 5000,
        dataType: 'JSONP',  // 处理Ajax跨域问题
        data: { callback: "?" },
        // 默认情况下，标准的跨域请求是不会发送cookie的
        xhrFields: {
            withCredentials: true
        },
        success: function (result) {
          if (result.status === 1) {
            _this.companyBoxShow = true;
            _this.switchCompanyDialog.show = false;
            // _this.$message.success(result.msg);
            // 在vuex中调用获取当前登录用户的购物车数量
            _this.$store.dispatch('getLoginCartNum');
            // 在vuex中调用获取当前登录用户的购物车
            _this.$store.dispatch('getLoginCartList');
            
          }
          else {
            _this.$message.error(result.msg);
          }
        }
      })
    },
    // 点击切换company
    switchCompanyBtn(item){
      if(!item.companyName){        
        this.switchCompanyDialog.show = false;
        console.log(item)
        this.$store.commit('openEditCompanyName',item.userID);
        this.$store.commit('openEditCompanyLogo',item.userAvatarUrl);
        this.$store.commit("openAddCompany");
      }else{
        this.userData.userInfo = item;
        this.saveCompanyInfo(item);
        this.reactLogin(item.userID);
      }
    },
    //   点击查询
    searchBtn (){
      if(!this.searchValue){
        return this.$message.info('请输入商品或礼包名称');
      }
      this.$emit('search-btn', this.searchValue);
    }
  },
};
</script>

<style scoped lang="less">
.top_search{
  display: flex;
  width: 1200px;
  height: 110px;
  margin: 0 auto;
  padding:30px 0;
  .row_logo{
    display: inline-flex;
    align-items:center;
    img{
      width: 126px;
      height:45px;
    }
    .logo_text{
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid #EFEFEF;
      p{
        font-size: 14px;
        font-weight: 400;
        color: #6F7387;
        line-height: 24px;       
      }
    }
  }  
  .row_search{
    display:inline-block;
    margin:5px 20px 0 50px;
    .input_box{
      width: 448px;      
      /deep/.el-input__inner{
        padding-left: 30px;
        border:1px solid #4C80FA !important;
        border-radius: 8px 0 0 8px !important;
      }
    }
    .serch_btn{
      width: 102px;
      background-color: #4C80FA;
      border-color: #4C80FA;
      color: #FFFFFF;
      letter-spacing: 2px;
      border-radius: 0 8px 8px 0 !important;
    }
  }  
  .page_search .input_box{
    width: 370px;
  }
  .my_cart_box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 138px;
    height: 50px;
    border-radius: 2px;
    border: 1px solid #EFEFEF;
    cursor: pointer;  
    .item {
      margin-top: 5px;
      margin-right: 10px;
    }
    .myCartText{
      font-size: 16px;
      font-weight: 400;
      color: #4C80FA;
    }
  }
  .row_company{
    display: flex;
    align-items: center;
    margin-left: 30px;
    .img_box{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .Auth{
        position: absolute;
        left: 0;
        width: 40px;
        bottom: 0;
        height: 19px;
        font-size: 10px;
        background: #000000;
        opacity: 0.3;
        border-radius: 0 0 100% 100%;
        display: flex;
        justify-content: center;
      }
      .goAuth{
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        font-size: 10px;
        border-radius: 50%;
        background: #000000;
        opacity: 0.3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .companyName{
      font-size: 18px;
      font-weight: 400;
      color: #3C3C3C;
      line-height: 24px;
      margin-left: 8px;
      margin-right: 3px;
      display: inline-block;
      max-width: 125px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    .change_icon{
      cursor: pointer;
      width: 21px;
      height: 21px;
      margin-left: 3px;
      background: url(../../assets/img/welfareMall/icon_change.png) no-repeat;
    }
  }
}
// 搜索 logo company style
  .header_search_box{
    background: #F8F8F8;
    height: 110px;
    display: flex;
    align-items: center;
    width: 100%;
    .search_box{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .row_logo{
        display: flex;
        align-items: center;
        img{
          width: 180px;
          height: 45px;
        }
      }
      .row_search{
        .input_box{
          width: 194px;
        }
        
      }
      .row_company{
        display: flex;
        align-items: center;
        .img_box{
          width: 56px;
          height: 56px;
          border-radius: 50%;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
          color: #FFFFFF;
          img{
            width: 56px;
            height: 56px;
            border-radius: 50%;
          }
          .Auth{
            position: absolute;
            left: 0;
            width: 56px;
            bottom: 0;
            height: 28px;
            background: #000000;
            opacity: 0.3;
            border-radius: 0 0 100% 100%;
            display: flex;
            justify-content: center;
          }
          .goAuth{
            position: absolute;
            top: 0;
            left: 0;
            width: 56px;
            height: 56px;
            border-radius: 50%;
            background: #000000;
            opacity: 0.3;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .companyName{
          margin-left: 8px;
          margin-right: 4px;
          display: inline-block;
          max-width: 230px;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
        .el-icon-sort{
          cursor: pointer;
          transform:rotate(90deg)
        }
        .el-icon-sort:hover{
          color: #1890FF;
        }
      }
      .my_cart_box{
        display: flex;
        justify-content: center;
        align-items: center;
        .box11{
          width: 138px;
          height: 48px;
          background: #F8F8F8;
          border: 1px solid #E0E0E0;
          padding-left: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .cart_icon{
            position: relative;
            .cart_num{
              display: inline-block;
              height: 16px;
              background: #F5222D;
              color: #fff;
              position: absolute;
              font-size: 12px;
              left: 0;
              top: 0;
            }
          }
          .myCartText{
            color: #666666;
            font-size: 16px;
          }
        }
        .box11:hover{
          border: 1px solid #F5222D;
        }
        
      }
      
    }
  }
  
.company_box{
  height: 350px;
  overflow: auto;
  li{
    height: 44px;
    cursor: pointer;
    padding: 12px 0 11px 12px;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
  li:hover{
    background: #E6F7FF;
    color: #1890FF;
  }
}
  /* 认证的弹窗 */
.auth_dialog .confirm-dialog .el-dialog__body{
    padding:30px 24px;
    color:#3c3c3c;
}
.auth_dialog .attention{
    line-height: 24px;
}
.auth_dialog .attention p{
    padding-bottom: 0;
    line-height: 24px;
}
.auth_dialog .attention span{
    padding-left: 0 !important;
}
.auth_dialog .attention .title_box .img{
    margin-left: 23px;
    width: 670px;
    height: 25px;
    background: url(/Images/hrPlatform/pic1.png) no-repeat;
    margin-bottom: 7px;
}
.auth_dialog .attention .title_box .img_text_box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.text_left{
    padding-left: 30px;
}
.text_color_blue{
    color: #0089CD ;
}
.text_color_blue_blod{
    font-weight: 700; 
    color: #0089CD !important;  
}
.text_color_black_blod{
    font-weight: 700; 
    color: #3c3c3c !important;  
}

.mb25{
    margin-bottom: 25px;
}
.dialog_footer{
    display: flex;
    justify-content: center;
}
.auth_dialog .dialog_footer .btn{
    border-color: #1890FF;
    color: #1890FF;
}
.auth_dialog .dialog_footer_box{
    display: flex;
    justify-content: center;
}
.auth_dialog .dialog_footer_box .cancleBtn{
    border: 1px solid #1890FF;
    color: #1890FF ;
}
.auth_dialog .dialog_footer_box .confirmBtn{
    background-color:#1890FF;
    margin-left: 24px;
    color: #fff;
}
</style>
